<template>
  <div>
    <v-card>
      <v-card-title>
        Edit Activity
        <v-spacer></v-spacer>
        <v-btn @click="save" color="primary">Save Activity</v-btn>
      </v-card-title>

      <v-card-text>
        <SurveyForm v-if="type == 'S'" 
          :activityData="activityList"
        />

        <TaskForm v-if="type == 'T'"
          :activityData="activityList"
        />
        <v-btn class="primary" @click="createNotification">Create notification</v-btn>
      </v-card-text>
    </v-card>

    <v-expansion-panels class="pt-4">
      <v-expansion-panel v-for="(notification, nIdx) in activityList.notifications" :key="nIdx">
        <v-expansion-panel-header><b>Notification {{ nIdx + 1 }}</b></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form">
            <v-select
              :items="status"
              item-text="name"
              item-value="_id"
              v-model="activityList.notifications[nIdx].enable"
              label="Notification Status"
              v-validate="'required'"
              data-vv-name="status"
              :error-messages="errors.collect('status')"
            ></v-select>

            <div v-if="activityList.notifications[nIdx].enable">
              <v-select
                v-model="activityList.notifications[nIdx].time"
                label="Time"
                :items="time"
                persistent-hint 
                hint="Notification Time (Local Time in all Time Zone)"
                v-validate="'required'"
                required
                data-vv-name="time"
                :error-messages="errors.collect('time')"
              ></v-select>

              <v-radio-group
                label="Recurring"
                v-model="activityList.notifications[nIdx].recurring"
                row
              >
                <v-radio label="Daily" value="daily"></v-radio>
                <v-radio label="Weekly" value="weekly"></v-radio>
                <v-radio label="Monthly" value="monthly"></v-radio>
              </v-radio-group>

              <v-select v-if="activityList.notifications[nIdx].recurring == 'weekly'"
                v-model="activityList.notifications[nIdx].daysOfWeek"
                label="Days of Week"
                :items="daysOfWeek"
                item-text="name"
                item-value="_id"
                v-validate="'required'"
                required
                multiple
                data-vv-name="days of week"
                :error-messages="errors.collect('days of week')"
              ></v-select>

              <v-select v-if="activityList.notifications[nIdx].recurring == 'monthly'"
                v-model="activityList.notifications[nIdx].daysOfMonth"
                label="Days of Month"
                v-validate="'required'"
                required
                :items="daysOfMonth"
                multiple
                data-vv-name="days on month"
                :error-messages="errors.collect('days of month')"
              ></v-select>
            </div>

            <v-btn color="primary" @click="removeNotificationPanel(notification, nIdx)">Delete</v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import SurveyForm from "./surveyForm.vue"
import TaskForm from "./taskForm.vue"

const service = new RestResource();
export default {
  components: {
    SurveyForm, TaskForm
  },
  data() {
    return {
      valid: false,
      status: [ 
        {
          _id: true,
          name: "Enable",
        },
        {
          _id: false,
          name: "Disable",
        }
      ],
      panel: [],
      numbers: [],
      dialog: false,
      success: false,
      failure: false,
      activityList:{
        notifications: []
      },
      daysOfWeek: [
        { _id: 0, name: "Sunday" },
        { _id: 1, name: "Monday" },
        { _id: 2, name: "Tuesday" },
        { _id: 3, name: "Wednesday" },
        { _id: 4, name: "Thrusday" },
        { _id: 5, name: "Friday" },
        { _id: 6, name: "Saturday" }
      ],
      daysOfMonth: [ 
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
        11, 12, 13, 14, 15, 16,  17, 18, 19, 20, 
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        31 
      ],
      time: [ 
        "00:30", "01:30", "02:30", "03:30", "04:30", "05:30", 
        "06:30", "07:30", "08:30", "09:30", "10:30", "11:30",
        "12:30", "13:30", "14:30", "15:30", "16:30", "17:30",
        "18:30", "19:30", "20:30", "21:30", "22:30", "23:30"
      ],
      selectType: null,
      type: null,
    };
  },

  mounted() {
    this.loadTask();
  },

  methods: {
    loadTask() {
      this.$setLoader();

      service.findActivityById(this.$route.params.id).then(r => {
        this.activityList = r.data
        this.type = r.data.type
        this.$disableLoader()
      });
    },
    createNotification() {
      this.activityList.notifications.push({});
    },
    removeNotificationPanel(nIdx, stidx) {
      this.activityList.notifications.splice(stidx, 1);
    },
    save() {
      if (this.$validator.validateAll()) {
        this.success = false;
        this.$setLoader();
     
        service.updateActivity(this.activityList).then(res => {
          this.$showSuccessMessage(res.data)
        })
      }
    }
  }
};
</script>