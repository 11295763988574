<template>
  <div>        
    <v-form ref="form">
      <v-text-field
        v-model="activityData.name"
        label="Name"
        v-validate="'required'"
        required
        data-vv-name="name"
        :error-messages="errors.collect('name')"
      ></v-text-field>

      <v-text-field
        v-model="activityData.alias"
        label="Alias"
        hint="Put it same as Task Name"
        persistent-hint
        v-validate="'required'"
        required
        data-vv-name="alias"
        :error-messages="errors.collect('alias')"
      ></v-text-field>

      <v-text-field
        v-model="activityData.description"
        label="Description"
        v-validate="'required'"
        required
        data-vv-name="description"
        :error-messages="errors.collect('description')"
      ></v-text-field>

      <p class="small">Note: Use the form below to edit the configuration manually.</p>
      <vue-json-editor v-model="activityData.config.pages" :show-btns="false" :expndedOnStart="true"></vue-json-editor> 
    </v-form>

    <v-file-input
      accept=".csv"
      @change="selectFile"
    />
  </div>
</template>

<script>
var csvjson = require("csvjson");
import taskGenerator from "./../../processor/task";
import vueJsonEditor from 'vue-json-editor'

export default {
  components: {
    vueJsonEditor
  },
  props: {
    activityData: Object
  },
  methods: {
    selectFile(e) {
      if (e) {
        const self = this;
        const file = e;

        this.isSelecting = false;
        this.btnText = file.name;

        let reader = new FileReader();
        reader.onload = () => {
          self.importedData = reader.result;
          self.dialogImport = true;

          let csvData = csvjson.toObject(this.importedData, {})
          let generatedTask = taskGenerator.init(csvData)
          this.activityData.config.pages = generatedTask.pages
        };

        reader.readAsText(file);
      }
    }
  }
};
</script>