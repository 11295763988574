<template>
  <v-form ref="form">
    <v-text-field
      v-model="activityData.name"
      label="Name"
      v-validate="'required'"
      required
      data-vv-name="name"
      :error-messages="errors.collect('name')"
    ></v-text-field>

    <v-text-field
      v-model="activityData.alias"
      label="Alias"
      v-validate="'required'"
      required
      data-vv-name="alias"
      :error-messages="errors.collect('alias')"
    ></v-text-field>

    <v-text-field
      v-model="activityData.loi"
      label="Length of Interview"
      v-validate="'required'"
      required
      data-vv-name="Length of Interview"
      :error-messages="errors.collect('Length of Interview')"
    ></v-text-field>

    <v-text-field
      v-model="activityData.config.url"
      label="URL"
      v-validate="'required|url'"
      required
      data-vv-name="url"
      :error-messages="errors.collect('url')"
    ></v-text-field>

    <v-textarea
      v-model="activityData.description"
      label="Description"
      v-validate="'required'"
      required
      data-vv-name="description"
      :error-messages="errors.collect('description')"
    ></v-textarea>

    <v-text-field
      label="Dashboard Project Id"
      v-model="activityData.config.dashboardProjectId"
    ></v-text-field>

    <v-text-field
      v-model="activityData.config.campaignId"
      label="Campaign Id"
      v-validate="'required'"
      required
      data-vv-name="Campaign Id"
      :error-messages="errors.collect('Campaign Id')"
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  props: {
    activityData: Object
  }
};
</script>
